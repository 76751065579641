// Include Bootstrap
import * as bootstrap from "bootstrap";

// Include jquery
import $ from "jquery";
window.jQuery = $;
window.$ = $;

// Include jquery ui widgets as needed
import "jquery-ui/ui/widgets/autocomplete";

// Include masonry
import Masonry from "masonry-layout";
window.Masonry = Masonry;

// NProgress
import NProgress from "nprogress";
window.NProgress = NProgress;
NProgress.configure({
	showSpinner: false,
});
NProgress.start();
NProgress.set(0.4);
var interval = setInterval(() => {
	NProgress.inc();
}, 1000);
window.addEventListener("load", () => {
	NProgress.done();
	clearInterval(interval);
});

/******************************** Global functions *************************************/

// Dom ready function
window.domReady = (callback) => {
	document.readyState === "interactive" || document.readyState === "complete"
		? callback()
		: document.addEventListener("DOMContentLoaded", callback);
};

// Get cookie function
window.getCookie = function (cname) {
	var name = cname + "=";
	var ca = document.cookie.split(";");
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == " ") {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "undefined";
};

// Set cookie function
window.setCookie = function (cname, cvalue, exdays) {
	var d = new Date();
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
	var expires = "expires=" + d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	return cvalue;
};

// Get json data
window.getJsonData = function (id, clear) {
	var $el = document.getElementById(id);
	if ($el) {
		var json = $el.textContent || $el.innerText;
		var data = JSON.parse(json);
		if (clear) {
			$el.innerHTML = "";
		}
		return data;
	} else {
		return undefined;
	}
};

// Decode html
window.decodeHtml = function (html) {
	var txt = document.createElement("textarea");
	txt.innerHTML = html;
	return txt.value;
};

/******************************** Theme functions *************************************/

// Sticky navbar
const $headerNav = document.getElementById("header-nav");
if ($headerNav) {
	let $headerTop = document.getElementById("header-top");
	let $headerMain = document.getElementById("header-main");
	let $siteLogo = $headerNav.querySelector(".site-logo");
	function stickyNav() {
		if (window.scrollY >= $headerTop.offsetHeight + $headerMain.offsetHeight && window.innerWidth <= 768) {
			$headerNav.classList.add("fixed-top");
			$siteLogo.classList.add("d-inline-block");
			document.body.style.paddingTop = $headerNav.offsetHeight + "px";
		} else {
			$headerNav.classList.remove("fixed-top");
			$siteLogo.classList.remove("d-inline-block");
			document.body.style.paddingTop = "0";
		}
	}
	window.addEventListener("scroll", () => {
		stickyNav();
	});
	window.addEventListener("resize", () => {
		stickyNav();
	});
	domReady(() => {
		stickyNav();
	});
}

// Set location
const $setLocation = document.getElementById("set-location");
if ($setLocation) {
	let locationCookie = decodeURIComponent(getCookie("LOCATION"));
	function setLocationLabel() {
		if (locationCookie != "undefined") {
			var location = JSON.parse(locationCookie);
			if (location && !location.DECLINED) {
				var label = location.LABEL;
				if (window.innerWidth >= 576) {
					$setLocation.querySelector(".dropdown-toggle > span").textContent = label;
				} else {
					$setLocation.querySelector(".dropdown-toggle > span").textContent = $setLocation.dataset.mobileText;
				}
				$setLocation.querySelector("input[name=location]").value = label;
				$setLocation.querySelector(".clear-button").classList.remove("d-none");
			}
		}
	}
	window.addEventListener("resize", () => {
		setLocationLabel();
	});
	domReady(() => {
		setLocationLabel();
	});
}

// Hide visit
const $hideVisit = document.getElementById("hide-visit");
if ($hideVisit) {
	$hideVisit.addEventListener("click", (e) => {
		e.preventDefault();
		hideVisit();
	});
	document.addEventListener("keyup", (e) => {
		if (e.key === "Escape") {
			hideVisit();
		}
	});
	function hideVisit() {
		window.open("https://www.google.com", "_blank");
		window.location.href = "https://www.yahoo.com";
	}
}

// Breadcrumbs
const $breadcumbs = document.getElementById("breadcrumbs");
if ($breadcumbs) {
	function moveBreadCrumbs() {
		var $breadcumbNav = document.getElementById("breadcrumb-nav");
		var $contentHeader = document.getElementById("content-header");
		if ($breadcumbNav && $contentHeader) {
			if (window.innerWidth <= 768) {
				$contentHeader.prepend($breadcumbNav);
			} else {
				$breadcumbs.appendChild($breadcumbNav);
			}
		}
	}
	window.addEventListener("resize", () => {
		moveBreadCrumbs();
	});
	domReady(() => {
		moveBreadCrumbs();
	});
}

// Back to top
const $backToTop = document.getElementById("back-to-top");
if ($backToTop) {
	$backToTop.addEventListener("click", (e) => {
		e.preventDefault();
		window.scroll({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	});
	window.addEventListener("scroll", () => {
		if (window.scrollY >= 400) {
			$backToTop.classList.remove("d-none");
			$backToTop.classList.add("d-block");
		} else {
			$backToTop.classList.remove("d-block");
			$backToTop.classList.add("d-none");
		}
	});
	function moveBackToTop() {
		if (window.__lc) {
			var $chatWidget = document.getElementById("chat-widget-container");
			if ($chatWidget) {
				$backToTop.style.bottom = "20px";
				$backToTop.style.left = "20px";
				$backToTop.style.right = "auto";
			} else {
				setTimeout(moveBackToTop, 100);
			}
		}
	}
	domReady(() => {
		moveBackToTop();
	});
}

// Tooltips
const $toolTips = document.querySelectorAll('[data-bs-toggle="tooltip"]');
if ($toolTips) {
	$toolTips.forEach(($toolTip) => {
		new bootstrap.Tooltip($toolTip);
	});
}

// Form validation
const $forms = document.querySelectorAll(".needs-validation");
if ($forms) {
	$forms.forEach(($form) => {
		$form.addEventListener(
			"submit",
			(e) => {
				if (!$form.checkValidity()) {
					e.preventDefault();
					e.stopPropagation();
				}
				$form.classList.add("was-validated");
			},
			false
		);
	});
}

// Accordion nav
const $accordion = document.querySelector(".accordion-nav .accordion.collapse");
if ($accordion && $accordion.querySelector(".accordion-item")) {
	let $accordionNavWrapper = document.querySelector(".accordion-nav-wrapper");
	let $accordionNav = document.querySelector(".accordion-nav");
	let $itemList = document.querySelector(".item-list") || document.querySelector(".classroom .lesson");
	$accordion.addEventListener("shown.bs.collapse", () => {
		if ($accordion.classList.contains("show")) {
			document.querySelector(".accordion-toggler .btn-back").classList.remove("d-none");
			document.querySelector(".accordion-toggler .btn-more").classList.add("d-none");
		}
	});
	$accordion.addEventListener("hidden.bs.collapse", () => {
		if (!$accordion.classList.contains("show")) {
			document.querySelector(".accordion-toggler .btn-back").classList.add("d-none");
			document.querySelector(".accordion-toggler .btn-more").classList.remove("d-none");
		}
	});
	function moveAccordionNav() {
		if ($accordionNav && $itemList) {
			var windowWidth = window.innerWidth;
			if (windowWidth <= 768) {
				$itemList.prepend($accordionNav);
			} else {
				$accordionNavWrapper.appendChild($accordionNav);
			}
		}
	}
	window.addEventListener("resize", () => {
		moveAccordionNav();
	});
	domReady(() => {
		moveAccordionNav();
	});
} else if ($accordion) {
	let $parentColumn = document.querySelector(".accordion-nav-wrapper").parentElement;
	$parentColumn.nextElementSibling.classList.remove("col-md-8");
	$parentColumn.remove();
}

// Text resizing
const $textSizer = document.getElementById("text-sizer");
if ($textSizer) {
	$textSizer.querySelector(".size-1").addEventListener("click", (e) => {
		e.preventDefault();
		resizeText(1);
		setCookie("text_size", 1, 30);
	});
	$textSizer.querySelector(".size-2").addEventListener("click", (e) => {
		e.preventDefault();
		resizeText(2);
		setCookie("text_size", 2, 30);
	});
	$textSizer.querySelector(".size-3").addEventListener("click", (e) => {
		e.preventDefault();
		resizeText(3);
		setCookie("text_size", 3, 30);
	});
	function resizeText(size) {
		if (size != "undefined") {
			size = Number(size);
			$textSizer.querySelectorAll("a").forEach((el) => {
				el.classList.remove("current-size");
			});
			$textSizer.querySelector("a.size-" + size).classList.add("current-size");
			var $body = document.querySelector("body");
			switch (size) {
				case 1:
					$body.classList.remove("big");
					$body.classList.add("small");
					break;
				case 2:
					$body.classList.remove("small", "big");
					break;
				case 3:
					$body.classList.remove("small");
					$body.classList.add("big");
					break;
				default:
					$body.classList.remove("small", "big");
					break;
			}
		}
	}
	resizeText(getCookie("text_size"));
}

// Site modal
const $siteModal = document.getElementById("site-modal");
if ($siteModal) {
	let modalShown = getCookie("modal-shown");
	if (modalShown == "undefined") {
		new bootstrap.Modal("#site-modal").show();
		setCookie("modal-shown", 1, 30);
	}
}

// Masonry layout
const $masonryGrid = document.querySelector(".masonry-grid");
if ($masonryGrid) {
	let msnry = new Masonry($masonryGrid, { percentPosition: true });
	window.addEventListener("beforeprint", () => {
		msnry.destroy();
	});
	window.addEventListener("afterprint", () => {
		msnry = new Masonry($masonryGrid, { percentPosition: true });
	});
}

/******************************** App specific functions *************************************/

// Auto suggest
$(function () {
	var $autoSuggest = $(".auto-suggest");
	if ($autoSuggest.length) {
		$autoSuggest.each(function () {
			var suggestURL = $(this).data("suggest-url");
			$(this).autocomplete({
				source: function (request, response) {
					$.getJSON(suggestURL + "?q=" + request.term, function (data) {
						response(
							$.map(data.suggestions, function (suggestion) {
								return {
									value: suggestion.term,
								};
							})
						);
					});
				},
				minLength: 2,
				delay: 100,
				select: function (event, ui) {
					$(this).val(ui.item.value);
				},
			});
		});
	}
});

// Topic cards
const $topicList = document.getElementById("topic-list");
if ($topicList) {
	$topicList.querySelectorAll(".card").forEach((el) => {
		el.addEventListener("click", (e) => {
			e.preventDefault();
			location.href = el.dataset.url;
		});
	});
	$topicList.querySelectorAll(".card .list-inline-item a").forEach((el) => {
		el.addEventListener("click", (e) => {
			e.stopPropagation();
		});
	});
}

// All topics
const $allTopics = document.getElementById("all-topics");
if ($allTopics) {
	$allTopics.addEventListener("click", (e) => {
		e.preventDefault();
		location.href = $allTopics.dataset.url;
	});
}

// Item cards (resources and orgs)
const $itemLists = document.querySelectorAll(".item-list");
if ($itemLists) {
	$itemLists.forEach(($list) => {
		$list.querySelectorAll(".card").forEach((el) => {
			el.addEventListener("click", (e) => {
				e.preventDefault();
				location.href = el.dataset.url;
			});
		});
		$list.querySelectorAll("a").forEach((el) => {
			el.addEventListener("click", (e) => {
				e.stopPropagation();
			});
		});
	});
}

// Search and Find legal help forms
const $form = document.getElementById("find-legal-help-form") || document.getElementById("advanced-search-form");
if ($form) {
	let locationCookie = decodeURIComponent(getCookie("LOCATION"));
	let $locationInput = $form.querySelector("input[name='location']");
	let $locationSelect = $form.querySelector("select[name='location']");
	if (locationCookie != "undefined" && ($locationInput || $locationSelect)) {
		let location = JSON.parse(locationCookie);
		if (location && !location.DECLINED) {
			if ($locationInput) {
				$locationInput.value = location.LABEL;
			} else {
				if (location.REGION) {
					$locationSelect.value = "region:" + location.REGION;
				} else {
					$locationSelect.value = "county:" + location.COUNTIES;
				}
			}
		}
	}
	let $tagSelect = $form.querySelector("select[name='tag']");
	if ($tagSelect) {
		$form.addEventListener("submit", () => {
			var $tagInput = $form.querySelector("input[name='taginput']");
			var $tagTypeSelect = $form.querySelector("select[name='tagtype']");
			var tagTypeValue = $tagTypeSelect.value;
			var tagValue = $tagSelect.value;
			if (tagTypeValue) {
				$tagInput.removeAttribute("disabled");
				$tagInput.setAttribute("name", "tag." + tagTypeValue);
				if (tagValue) $tagInput.value = tagValue;
			}
			return true;
		});
	}
	$form.querySelectorAll("select[data-child]").forEach((el) => {
		var childId = el.getAttribute("data-child");
		var childData = getJsonData(childId + "-data", true);
		var $childSelect = $form.querySelector("#" + childId);
		el.addEventListener("change", () => {
			updateChildOptions(el, $childSelect, childData);
		});
		var selectedValue = el.value;
		if (selectedValue) {
			updateChildOptions(el, $childSelect, childData);
		}
	});
	function updateChildOptions(parent, child, data) {
		var selectedValue = parent.value;
		var values = data[selectedValue];
		if (selectedValue && values.length) {
			child.removeAttribute("disabled");
			child.parentElement.classList.remove("disabled");
			while (child.options.length > 0) {
				child.remove(0);
			}
			var childValue = parent.getAttribute("data-child-value");
			for (var i = 0; i < values.length; i++) {
				var opt = new Option(decodeHtml(values[i].label), values[i].slug);
				if (values[i].slug == childValue) {
					opt.setAttribute("selected", "selected");
				}
				child.options.add(opt);
			}
		} else {
			child.setAttribute("disabled", "disabled");
			child.parentElement.classList.add("disabled");
			while (child.options.length > 0) {
				child.remove(0);
			}
		}
	}
}

// Directory map
const $orgMap = document.getElementById("organization-map");
if ($orgMap) {
	function initMap() {
		var mapData = getJsonData("map-data", true);
		if (mapData) {
			if (mapData.length > 1) {
				var latLng = new google.maps.LatLng(0, 0);
			} else {
				var latLng = new google.maps.LatLng(mapData[0].latitude, mapData[0].longitude);
			}
			var map = new google.maps.Map(document.getElementById("google-map"), {
				center: latLng,
				zoom: mapData.length > 1 ? 10 : 13,
				disableDefaultUI: true,
			});
			var infoWindow = new google.maps.InfoWindow();
			var bounds = new google.maps.LatLngBounds();
			for (var i = 0; i < mapData.length; i++) {
				var latLng = new google.maps.LatLng(mapData[i].latitude, mapData[i].longitude);
				var marker = new google.maps.Marker({
					position: latLng,
					map: map,
					title: mapData[i].name,
					url: mapData[i].url,
				});
				google.maps.event.addListener(marker, "click", function () {
					infoWindow.setContent('<div><h6><a href="' + this.url + '">' + this.title + "</a></h6></div>");
					infoWindow.open(map, this);
				});
				if (mapData.length > 1) {
					bounds.extend(latLng);
					map.fitBounds(bounds);
				}
			}
			if (mapData.length > 1) {
				google.maps.event.addDomListener(window, "resize", function () {
					google.maps.event.trigger(map, "resize");
					map.fitBounds(bounds);
				});
			}
		}
	}
	domReady(() => {
		initMap();
	});
}

// Interview form
const $interviewForm = document.getElementById("interview-form");
if ($interviewForm) {
	let $nextButton = $interviewForm.querySelector("#next");
	if ($nextButton) {
		$nextButton.addEventListener("click", () => {
			$interviewForm.setAttribute("action", $interviewForm.dataset.nextUrl);
			return true;
		});
	}
	let $backButton = $interviewForm.querySelector("#back");
	if ($backButton) {
		$backButton.addEventListener("click", () => {
			$interviewForm.setAttribute("action", $interviewForm.dataset.prevUrl);
			return true;
		});
	}
	let $issuePage = $interviewForm.querySelector(".issue-page");
	if ($issuePage) {
		let $currentTopic = $interviewForm.querySelector("input[type=radio]:checked");
		let currentTopicValue = "";
		if ($currentTopic) {
			currentTopicValue = $currentTopic.value;
		}
		$interviewForm.querySelectorAll("input[type=radio]").forEach((el) => {
			el.addEventListener("click", () => {
				if (currentTopicValue != el.value) {
					var $subtopic = $interviewForm.querySelector("#subtopic");
					if ($subtopic) {
						$subtopic.value = "";
					}
					var $category = $interviewForm.querySelector("#category");
					if ($category) {
						$category.value = "";
					}
				}
				$nextButton.click();
			});
		});
		$interviewForm.querySelectorAll(".card").forEach((el) => {
			el.addEventListener("click", () => {
				el.querySelector("input[type=radio]").click();
			});
		});
	}
	let $refinePage = $interviewForm.querySelector(".refine-page");
	if ($refinePage) {
		let categoryData = getJsonData("category-data", false);
		let $categoryHidden = $interviewForm.querySelector("#category-hidden");
		let $categoryList = $interviewForm.querySelector("#category-list");
		let $subtopicMenu = $interviewForm.querySelector("#subtopic-menu");
		let $subtopicHidden = $interviewForm.querySelector("#subtopic-hidden");
		let subtopicValue = $subtopicMenu ? $subtopicMenu.value : $subtopicHidden.value;
		if (subtopicValue) {
			let categoryValues = categoryData[subtopicValue];
			let categoryValue = $categoryHidden.value;
			if (categoryValues.length && categoryValues.length > 1) {
				$categoryHidden.value = "";
				$categoryHidden.disabled = true;
				$categoryList.innerHTML = buildCategoryList(categoryValues);
				$categoryList.style.display = "block";
				if (categoryValue) {
					$categoryList.querySelector("input[type=radio][value=" + categoryValue + "]").checked = true;
				} else {
					$categoryList.querySelector("input[type=radio]").checked = true;
				}
			} else {
				$categoryHidden.disabled = false;
			}
		}
		if ($subtopicMenu) {
			$subtopicMenu.addEventListener("change", () => {
				var categoryValues = categoryData[$subtopicMenu.value];
				$categoryList.style.display = "none";
				$categoryList.innerHTML = "";
				$categoryHidden.value = "";
				$categoryHidden.disabled = true;
				if (categoryValues.length && categoryValues.length > 1) {
					$categoryList.innerHTML = buildCategoryList(categoryValues);
					$categoryList.style.display = "block";
					$categoryList.querySelector("input[type=radio]").checked = true;
				} else if (categoryValues.length && categoryValues.length == 1) {
					$categoryHidden.value = categoryValues[0].slug;
					$categoryHidden.disabled = false;
				}
			});
		}
		function buildCategoryList(categoryValues) {
			var elements = "";
			categoryValues.forEach((cat) => {
				elements +=
					'<div class="form-check mb-3"><label class="form-check-label" for="' +
					cat.slug +
					'"><input class="form-check-input" type="radio" name="category" value="' +
					cat.slug +
					'" id="' +
					cat.slug +
					'">' +
					cat.label +
					"</label></div>";
			});
			return elements;
		}
	}
}

// FAQ Accordions
const $faqAccordionItems = document.querySelectorAll(".faq .accordion .accordion-collapse");
if ($faqAccordionItems) {
	$faqAccordionItems.forEach(($item) => {
		$item.addEventListener("shown.bs.collapse", () => {
			var $parent = $item.parentNode;
			if ($parent.getBoundingClientRect().top < 0) {
				var scrollOffset = $parent.offsetTop;
				window.scroll({
					top: scrollOffset,
					left: 0,
					behavior: "smooth",
				});
			}
		});
	});
}

// Alert widgets
const $alertWidgets = document.querySelectorAll(".alert-widget");
if ($alertWidgets) {
	$alertWidgets.forEach(($item) => {
		$item.addEventListener("closed.bs.alert", () => {
			setCookie("alert-widget-" + $item.id, "closed", 1);
		});
		if (getCookie("alert-widget-" + $item.id) == "closed") {
			$item.classList.add("d-none");
		}
	});
}

/******************************* Location tracking ***********************************/

const locationCookie = decodeURIComponent(getCookie("LOCATION"));
if (locationCookie != "undefined") {
	let location = JSON.parse(locationCookie);
	if (location && !location.DECLINED) {
		document
			.querySelectorAll(
				".topic-list a.subtopic,.subtopic-list a,.channel-tabs .nav-link,.filter-bar .dropdown-item,.topic-subtopic-nav .dropdown-item,.channel-nav .accordion-header a,.channel-nav .list-unstyled a"
			)
			.forEach((el) => {
				if (el.href.indexOf("location=") == -1) {
					if (el.href.indexOf("?") >= 0) {
						el.setAttribute("href", el.href + "&location=" + encodeURIComponent(location.LABEL));
					} else {
						el.setAttribute("href", el.href + "?location=" + encodeURIComponent(location.LABEL));
					}
				}
			});
	}
}

/******************************** Event tracking *************************************/

/*
	ga:dimension1 - Locale - locale
	ga:dimension2 - CountyID - county_id
	ga:dimension3 - County Name - county_name
	ga:dimension4 - ResourceID - resource_id
	ga:dimension5 - Resource Name - resource_name
	ga:dimension6 - Resource Author - resource_author
	ga:dimension7 - Date Modified - date_modified
	ga:dimension8 - OrganizationID - organization_id
	ga:dimension9 - Organization Name - organization_name
	ga:dimension10 - Zip Code - zip_code
	ga:dimension11 - CityID - city_id
	ga:dimension12 - City Name - city_name
*/

// Rating widget
const $ratingWidget = document.getElementById("rating-widget");
if ($ratingWidget) {
	$ratingWidget.querySelectorAll("a").forEach((el) => {
		el.addEventListener("click", (e) => {
			e.preventDefault();
			/*
			if (typeof _gaq != "undefined") {
				_gaq.push(["_trackEvent", "Rating", el.dataset.rating, el.pathname]);
			}
			if (typeof ga != "undefined") {
				ga("send", "event", {
					eventCategory: "Rating",
					eventAction: el.dataset.rating,
					eventLabel: el.pathname,
					eventValue: el.dataset.value,
					dimension1: el.dataset.locale,
					dimension4: el.dataset.id,
				});
			}
			*/
			if (typeof gtag != "undefined") {
				gtag("event", "rating_set", {
					rating_text: el.dataset.rating,
					rating_value: el.dataset.value,
					resource_url: el.pathname,
					resource_id: el.dataset.id,
					locale: el.dataset.locale,
				});
			}
			alert($ratingWidget.dataset.message);
		});
	});
}

// Share this buttons
const $shareThisButtons = document.querySelector(".sharethis-inline-share-buttons");
if ($shareThisButtons) {
	$shareThisButtons.addEventListener("click", (e) => {
		e.preventDefault();
		var $this = e.target;
		var href = document.location.href;
		if ($this.tagName == "IMG") {
			var network = $this.parentElement.dataset.network;
			/*
			if (typeof _gaq != "undefined") {
				_gaq.push(["_trackEvent", "Share", network, href]);
			}
			if (typeof ga != "undefined") {
				ga("send", "event", {
					eventCategory: "Share",
					eventAction: network,
					eventLabel: href,
				});
			}
			*/
			if (typeof gtag != "undefined") {
				gtag("event", "share", {
					link_url: href,
					share_network: network,
				});
			}
		}
	});
}

// TODO: Rewrite in plain js
// Event tracking
$(function () {
	/*
	dimension1 = Language/LocaleID - locale
	dimension2 = CountyID - county_id
	dimension3 - CountyName - county_name
	dimension4 = ResourceID - resource_id
	*/
	if (typeof gtag != "undefined") {
		/*
		if (typeof gaDimensions == "undefined") {
			var gaDimensions = {};
			gaDimensions["dimension2"] = "UNKNOWN";
			gaDimensions["dimension3"] = "UNKNOWN";
		}
		*/
		if (typeof ga4Dimensions == "undefined") {
			var ga4Dimensions = {};
			ga4Dimensions["county_id"] = "UNKNOWN";
			ga4Dimensions["county_name"] = "UNKNOWN";
		}
		$("a.download-link").on("click", function (e) {
			var $this = $(this);
			var href = $this.attr("href").split("?")[0];
			var fileType = $this.attr("filetype");
			var localeId = $this.attr("localeid").toUpperCase();
			var resourceId = $this.attr("resourceid");
			/*
			if (typeof _gaq != "undefined") {
				_gaq.push(["_trackEvent", "Downloads", fileType, href]);
			}
			if (typeof ga != "undefined") {
				ga("send", "event", {
					eventCategory: "Downloads",
					eventAction: fileType,
					eventLabel: href,
					dimension1: localeId,
					dimension2: gaDimensions["dimension2"],
					dimension3: gaDimensions["dimension3"],
					dimension4: resourceId,
				});
			}
			*/
			// This is also collected by GA4, how do we attach to it and include locale, county info and resource id without sending here?
			if (typeof gtag != "undefined") {
				gtag("event", "file_download", {
					file_extension: fileType,
					link_url: href,
					locale: localeId,
					county_id: ga4Dimensions["county_id"],
					county_name: ga4Dimensions["county_name"],
					resource_id: resourceId,
				});
			}
			setTimeout(function () {
				location.href = href;
			}, 200);
			return false;
		});
		$("a.external-link").on("click", function (e) {
			var $this = $(this);
			var href = $this.attr("href").split("?")[0];
			var target = $this.attr("target") ? $this.attr("target") : "";
			var localeId = $this.attr("localeid").toUpperCase();
			var resourceId = $this.attr("resourceid");
			/*
			if (typeof _gaq != "undefined") {
				_gaq.push(["_trackEvent", "Links", "Click", href]);
			}
			if (typeof ga != "undefined") {
				ga("send", "event", {
					eventCategory: "Links",
					eventAction: "Click",
					eventLabel: href,
					dimension1: localeId,
					dimension2: gaDimensions["dimension2"],
					dimension3: gaDimensions["dimension3"],
					dimension4: resourceId,
				});
			}
			*/
			if (typeof gtag != "undefined") {
				gtag("event", "click", {
					link_url: href,
					locale: localeId,
					county_id: ga4Dimensions["county_id"],
					county_name: ga4Dimensions["county_name"],
					resource_id: resourceId,
				});
			}
			if (target.length == 0) {
				setTimeout(function () {
					location.href = href;
				}, 200);
				return false;
			}
		});
		$("audio.audio-player").on("play", function (e) {
			var $this = $(this);
			var href = $this.attr("src").split("?")[0];
			var localeId = $this.attr("localeid").toUpperCase();
			var resourceId = $this.attr("resourceid");
			/*
			if (typeof _gaq != "undefined") {
				_gaq.push(["_trackEvent", "Downloads", "MP3", href]);
			}
			if (typeof ga != "undefined") {
				ga("send", "event", {
					eventCategory: "Downloads",
					eventAction: "MP3",
					eventLabel: href,
					dimension1: localeId,
					dimension2: gaDimensions["dimension2"],
					dimension3: gaDimensions["dimension3"],
					dimension4: resourceId,
				});
			}
			*/
			if (typeof gtag != "undefined") {
				gtag("event", "audio_play", {
					link_url: href,
					locale: localeId,
					county_id: ga4Dimensions["county_id"],
					county_name: ga4Dimensions["county_name"],
					resource_id: resourceId,
				});
			}
			$this.off("play");
		});
		$("a")
			.not(
				"a.amp_modal, .download-link, .external-link, .nav-tabs a, .pagination a, .accordion-nav a, .breadcrumbs a, .sidebar a, .topic-list a, .subtopic-list a, .header a, .topic-subtopic-nav a"
			)
			.on("click", function (e) {
				var $this = $(this);
				var hostName = $this[0].hostname;
				var pathName = $this[0].pathname;
				var fullHref = $this.attr("href");
				var href = $this.attr("href").split("?")[0];
				var target = $this.attr("target") ? $this.attr("target") : "";
				var fileType = $this.attr("filetype") ? $this.attr("filetype") : "";
				var localeId = $this.attr("localeid") ? $this.attr("localeid").toUpperCase() : "";
				var resourceId = $this.attr("resourceid") ? $this.attr("resourceid") : "";
				var isPDF = pathName.substring(pathName.lastIndexOf(".") + 1).toUpperCase() == "PDF" ? true : false;
				if (isPDF) fileType = "PDF";
				var isExternal = location.hostname != hostName;
				if (isPDF || isExternal) {
					if (isPDF) {
						/*
						if (typeof _gaq != "undefined") {
							_gaq.push(["_trackEvent", "Downloads", fileType, href]);
						}
						if (typeof ga != "undefined") {
							var eventObject = {
								eventCategory: "Downloads",
								eventAction: fileType,
								eventLabel: href,
								dimension2: gaDimensions["dimension2"],
								dimension3: gaDimensions["dimension3"],
							};
							if (localeId.length) eventObject["dimension1"] = localeId;
							if (resourceId.length) eventObject["dimension4"] = resourceId;
							ga("send", "event", eventObject);
						}
						*/
						if (typeof gtag != "undefined") {
							gtag("event", "file_download", {
								file_extension: fileType,
								link_url: href,
								locale: localeId,
								county_id: ga4Dimensions["county_id"],
								county_name: ga4Dimensions["county_name"],
								resource_id: resourceId,
							});
						}
					} else {
						/*
						if (typeof _gaq != "undefined") {
							_gaq.push(["_trackEvent", "Links", "Click", href]);
						}
						if (typeof ga != "undefined") {
							var eventObject = {
								eventCategory: "Links",
								eventAction: "Click",
								eventLabel: href,
								dimension2: gaDimensions["dimension2"],
								dimension3: gaDimensions["dimension3"],
							};
							if (localeId.length) eventObject["dimension1"] = localeId;
							if (resourceId.length) eventObject["dimension4"] = resourceId;
							ga("send", "event", eventObject);
						}
						*/
						if (typeof gtag != "undefined") {
							gtag("event", "click", {
								link_url: href,
								locale: localeId,
								county_id: ga4Dimensions["county_id"],
								county_name: ga4Dimensions["county_name"],
								resource_id: resourceId,
							});
						}
					}
				}
				if (target.length == 0) {
					setTimeout(function () {
						location.href = fullHref;
					}, 200);
					return false;
				}
			});
	}
});
